import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { PagedRequestParams, formatQueryParams } from '@top-solution/microtecnica-utils';
import {
  CompletedTask,
  CompletedTaskForm,
  CompletedTaskSchema,
  Task,
  TaskCreateRequest,
  TaskOverrideCreateRequest,
  TaskSchema,
  TaskUserUpdateRequest,
} from '../entities/Task';
import { api, TAG_TYPES } from './baseApi';

const tasksUrl = 'tasks';
const completedTasksUrl = 'tasks/completed-tasks';

type ReadTaskListParams = PagedRequestParams & {
  excludeDeleted?: string;
  hasUpdatedTask?: boolean;
};

const taskApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readTaskList: builder.query<Task[], ReadTaskListParams>({
      query: ({ excludeDeleted, ...params }) => {
        const qParams = formatQueryParams(params);
        if (excludeDeleted) {
          qParams.set('excludeDeleted', excludeDeleted);
        }
        return { url: tasksUrl, params: qParams };
      },
      transformResponse: (data) => TaskSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.TASK, id: 'LIST' }],
    }),
    createTask: builder.mutation<void, TaskCreateRequest>({
      query: (body) => ({ url: `${tasksUrl}`, method: 'POST', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.TASK, id: 'LIST' }],
    }),
    updateTask: builder.mutation<void, TaskCreateRequest & { id: Task['id'] }>({
      query: ({ id, ...body }) => ({ url: `${tasksUrl}/${id}`, method: 'PUT', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.TASK, id: 'LIST' }],
    }),
    updateUserTask: builder.mutation<void, TaskUserUpdateRequest>({
      query: ({ id, ...body }) => ({ url: `${tasksUrl}/${id}`, method: 'PUT', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.TASK, id: 'LIST' }],
    }),
    deleteTask: builder.mutation<void, { id: Task['id'] }>({
      query: ({ id }) => ({ url: `${tasksUrl}/${id}`, method: 'DELETE' }),
      invalidatesTags: () => [{ type: TAG_TYPES.TASK, id: 'LIST' }],
    }),
    batchUpdateTasks: builder.mutation<number[], Array<TaskCreateRequest & { id: Task['id'] }>>({
      queryFn: async (tasks, _queryApi, _extraOptions, fetchWithBQ) => {
        const updated: number[] = [];
        const errors: Record<number, FetchBaseQueryError> = {};
        for (let i = 0; i < tasks.length; i++) {
          const task = tasks[i];
          try {
            const response = await fetchWithBQ({ url: `${tasksUrl}/${task.id}`, method: 'PUT', body: task });
            if (response.error) {
              errors[task.id] = response.error;
            } else {
              updated.push(task.id);
            }
          } catch (error) {
            errors[task.id] = error as FetchBaseQueryError;
          }
        }
        if (Object.keys(errors).length > 0) {
          const error = {
            status: 'CUSTOM_ERROR',
            data: errors,
            error: `Error deleting hotels ${Object.keys(errors).join(', ')}`,
          } as FetchBaseQueryError;
          return { error };
        }
        return { data: updated };
      },
      invalidatesTags: () => [{ type: TAG_TYPES.TASK, id: 'LIST' }],
    }),
    batchDeleteTasks: builder.mutation<number[], Array<Task['id']>>({
      queryFn: async (tasksIds, _queryApi, _extraOptions, fetchWithBQ) => {
        const updated: number[] = [];
        const errors: Record<number, FetchBaseQueryError> = {};
        for (let i = 0; i < tasksIds.length; i++) {
          const taskId = tasksIds[i];
          try {
            const response = await fetchWithBQ({ url: `${tasksUrl}/${taskId}`, method: 'DELETE' });
            if (response.error) {
              errors[taskId] = response.error;
            } else {
              updated.push(taskId);
            }
          } catch (error) {
            errors[taskId] = error as FetchBaseQueryError;
          }
        }
        if (Object.keys(errors).length > 0) {
          const error = {
            status: 'CUSTOM_ERROR',
            data: errors,
            error: `Error deleting hotels ${Object.keys(errors).join(', ')}`,
          } as FetchBaseQueryError;
          return { error };
        }
        return { data: updated };
      },
      invalidatesTags: () => [{ type: TAG_TYPES.TASK, id: 'LIST' }],
    }),
    updateTaskStatus: builder.mutation<void, TaskCreateRequest & { id: number }>({
      query: ({ id, ...body }) => ({ url: `${tasksUrl}/${id}`, method: 'PUT', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.TASK, id: 'LIST' }],
    }),
    readCompletedTaskList: builder.query<CompletedTask[], void>({
      query: () => ({ url: completedTasksUrl }),
      transformResponse: (data) => CompletedTaskSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.COMPLETED_TASK, id: 'LIST' }],
    }),
    createCompletedTask: builder.mutation<void, CompletedTaskForm>({
      query: (body) => ({ url: `${completedTasksUrl}`, method: 'POST', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.COMPLETED_TASK, id: 'LIST' }],
    }),
    updateCompletedTask: builder.mutation<void, CompletedTaskForm & { id: number }>({
      query: ({ id, ...body }) => ({ url: `${completedTasksUrl}/${id}`, method: 'PUT', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.COMPLETED_TASK, id: 'LIST' }],
    }),
    deleteCompletedTask: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({ url: `${completedTasksUrl}/${id}`, method: 'DELETE' }),
      invalidatesTags: () => [{ type: TAG_TYPES.COMPLETED_TASK, id: 'LIST' }],
    }),
    createTaskOverride: builder.mutation<void, TaskOverrideCreateRequest>({
      query: (body) => ({ url: `${tasksUrl}`, method: 'POST', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.TASK, id: 'LIST' }],
    }),
  }),
});

export const {
  useReadTaskListQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useUpdateUserTaskMutation,
  useDeleteTaskMutation,
  useBatchUpdateTasksMutation,
  useBatchDeleteTasksMutation,
  useReadCompletedTaskListQuery,
  useCreateCompletedTaskMutation,
  useUpdateCompletedTaskMutation,
  useDeleteCompletedTaskMutation,
  useCreateTaskOverrideMutation,
} = taskApi;
