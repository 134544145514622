import { useMemo } from 'react';
import { Area } from '../entities/Area';
import { useReadAreaListQuery } from '../services/areaApi';

export function useAreasById() {
  const { data } = useReadAreaListQuery();

  return useMemo(() => {
    const areasById = new Map<Area['id'], Area>();
    for (const area of data ?? []) {
      areasById.set(area.id, area);
    }
    return areasById;
  }, [data]);
}
