import { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { AuthGuard, useAuth } from '@top-solution/microtecnica-utils';
import { isAfter, isBefore, previousMonday } from 'date-fns';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { UserAutocomplete } from '../../components/Form/UserAutocomplete';
import { GanttTasksContext } from '../../components/GanttTasksProvider';
import { MagnifyMinusIcon, MagnifyPlusIcon, RefreshIcon } from '../../components/Icons';
import { Layout } from '../../components/Layout';
import { LinkButton } from '../../components/LinkButton';
import { GanttTask } from '../../entities/Task';
import { useGanttTasksStats } from '../../hooks/useGanttTasksStats';
import { usePlannerConfig } from '../../hooks/usePlannerConfig';
import { useReadTurnbackListQuery } from '../../services/turnbackApi';
import { useReadUserListQuery } from '../../services/userApi';
import { dateRangePickerShortcutsItems, formatFromDate, formatToDate, parseISODate } from '../../utils/date';
import { UnauthorizedPage } from '../Error/UnauthorizedPage';
import { AssigneeVisibilitySelector } from './AssigneeVisibilitySelector';
import { GanttDataGrid } from './GanttDataGrid';
import { GanttTaskDetailsDialog } from './GanttTaskDetailsDialog';
import { GanttTitle } from './GanttTitle';

const PAGE_TITLE = 'Task Gantt';

export function GanttPage(): JSX.Element {
  const auth = useAuth();
  const userList = useReadUserListQuery();
  const turnbackList = useReadTurnbackListQuery();
  const { config, patchConfig } = usePlannerConfig();

  const [qs, setQs] = useSearchParams();

  const [selectedTask, setSelectedTask] = useState<GanttTask | null>(null);

  const username = useMemo(() => (qs.has('username') ? qs.get('username') : auth.username), [auth.username, qs]);
  const zoomLevel = useMemo(
    () => (config?.pages?.gantt?.params?.zoomLevel ? config.pages.gantt.params.zoomLevel : 2),
    [config],
  );

  const from = useMemo(() => {
    if (qs.has('from')) {
      if (qs.get('from')) {
        const from = parseISODate(qs.get('from'));
        if (from && isFinite(from.getTime())) {
          return from;
        }
      }
    } else if (config?.pages?.gantt?.params?.from) {
      return new Date(config?.pages?.gantt?.params?.from);
    }
    return previousMonday(new Date());
  }, [config, qs]);

  const to = useMemo(() => {
    if (qs.has('to')) {
      if (qs.get('to')) {
        const to = parseISODate(qs.get('to'));
        if (to && isFinite(to.getTime())) {
          return to;
        }
      }
    } else if (config?.pages?.gantt?.params?.from) {
      return new Date(config?.pages?.gantt?.params?.from);
    }
    return new Date();
  }, [config, qs]);

  const [datePickerRange, setDatePickerRange] = useState<[Date | null, Date | null]>([from, to]);

  const owner = useMemo(() => {
    const owner = qs.get('owner');
    if (owner && owner === '1') {
      return true;
    } else if (config?.pages?.gantt?.params?.owner !== undefined) {
      return config.pages.gantt.params.owner;
    }
    return false;
  }, [config, qs]);

  const backup = useMemo(() => {
    const backup = qs.get('backup');
    if (backup && backup === '1') {
      return true;
    } else if (config?.pages?.gantt?.params?.backup !== undefined) {
      return config.pages.gantt.params.backup;
    }
    return false;
  }, [config, qs]);

  const reviewer = useMemo(() => {
    const reviewer = qs.get('reviewer');
    if (reviewer && reviewer === '1') {
      return true;
    } else if (config?.pages?.gantt?.params?.reviewer !== undefined) {
      return config.pages.gantt.params.reviewer;
    }
    return false;
  }, [config, qs]);

  // const {
  //   ganttTasks,
  //   error: ganttTasksError,
  //   isFetching: ganttTaskIsFetching,
  //   refetch,
  // } = useGanttTasks({
  //   from,
  //   to,
  //   owner: owner ? username : null,
  //   backup: backup ? username : null,
  //   reviewer: reviewer ? username : null,
  //   skip: username === '' || !from || !to || (!owner && !backup && !reviewer),
  // });

  const {
    ganttTasks,
    error: ganttTasksError,
    isFetching: ganttTaskIsFetching,
    refetch,
  } = useContext(GanttTasksContext);

  const filteredGanttTasks = useMemo(() => {
    return ganttTasks.filter((ganttTask) => {
      if (isBefore(ganttTask.date, from) || isAfter(ganttTask.date, to)) {
        return false;
      }
      if (
        ganttTask.task.owner !== username &&
        ganttTask.task.backup !== username &&
        ganttTask.task.reviewer !== username
      ) {
        return false;
      }
      return true;
    });
  }, [from, ganttTasks, to, username]);

  useEffect(() => {
    setDatePickerRange([from, to]);
  }, [from, to]);

  function changeZoomLevel(zoomLevel: number) {
    patchConfig({ pages: { gantt: { params: { zoomLevel } } } });
  }

  const { finance, supplier } = useGanttTasksStats(
    ganttTasks,
    {
      ownerUsername: username ?? undefined,
    },
    {
      excludeFutureTasks: false,
      ignoreOtherScenario: false,
    },
  );

  const onTime =
    supplier.completion + finance.completion === 0
      ? undefined
      : (supplier.onTime + finance.onTime) / (supplier.completion + finance.completion);
  const completion =
    supplier.total + finance.total === 0
      ? undefined
      : (supplier.completion + finance.completion) / (supplier.total + finance.total);

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout
        title={PAGE_TITLE}
        maxWidth={false}
        inProgress={turnbackList.isFetching || userList.isFetching}
        error={turnbackList.error || userList.error}
        breadcrumbs={[
          {
            title: PAGE_TITLE,
            url: '/gantt',
          },
        ]}
        headerFilters={
          <Stack direction="row" gap={1} flex={1}>
            <DateRangePicker
              value={datePickerRange}
              onChange={(range) => {
                setDatePickerRange(range);
              }}
              disableAutoMonthSwitching
              onAccept={([fromDate, toDate]) => {
                if (fromDate && isFinite(fromDate.getTime())) {
                  if (toDate && isFinite(toDate.getTime())) {
                    setQs((oldParams) => {
                      const params = new URLSearchParams(oldParams);
                      params.set('from', formatFromDate(fromDate));
                      params.set('to', formatToDate(toDate));
                      return params;
                    });
                  }
                }
              }}
              slots={{
                fieldSeparator: () => null,
              }}
              slotProps={{
                textField: {
                  variant: 'filled',
                  size: 'small',
                  sx: { width: '13ch' },
                },
                shortcuts: {
                  items: dateRangePickerShortcutsItems,
                },
              }}
              sx={{
                '.MuiFormControl-root': {
                  marginX: 0.5,
                },
              }}
            />
            <ButtonGroup size="small" sx={{ marginLeft: 0.5 }}>
              <Button color="secondary" onClick={() => changeZoomLevel(zoomLevel - 1)} disabled={zoomLevel === 0}>
                <MagnifyMinusIcon />
              </Button>
              <Button color="secondary" onClick={() => changeZoomLevel(zoomLevel + 1)} disabled={zoomLevel === 5}>
                <MagnifyPlusIcon />
              </Button>
            </ButtonGroup>
            <Stack direction="column" justifyContent="center" marginX={1}>
              <Button
                variant="outlined"
                onClick={() => refetch()}
                size="small"
                startIcon={<RefreshIcon />}
                disabled={ganttTaskIsFetching}
              >
                Refresh
              </Button>
            </Stack>
          </Stack>
        }
      >
        <GanttTitle title={PAGE_TITLE} onTime={onTime} completion={completion}>
          <Stack
            direction="row"
            columnGap={1.5}
            rowGap={0.75}
            alignItems="center"
            flex={1}
            flexWrap="wrap"
            justifyContent="center"
          >
            <UserAutocomplete
              size="small"
              variant="outlined"
              value={username}
              onChange={(_, username) => {
                if (username && !Array.isArray(username)) {
                  setQs((prevQs) => {
                    const params = new URLSearchParams(prevQs);
                    params.set('username', username);
                    return params;
                  });
                }
              }}
              sx={{ minWidth: '292px' }}
            />
            <Typography variant="body2" whiteSpace="nowrap">
              {'assigned as'}
            </Typography>
            <Box>
              <AssigneeVisibilitySelector
                backup={backup}
                owner={owner}
                reviewer={reviewer}
                onAssegneeVisibilityChange={({ owner, backup, reviewer }) => {
                  setQs((prevQs) => {
                    const params = new URLSearchParams(prevQs);
                    params.set('owner', owner ? '1' : '0');
                    params.set('backup', backup ? '1' : '0');
                    params.set('reviewer', reviewer ? '1' : '0');
                    return params;
                  });
                  patchConfig({ pages: { gantt: { params: { owner, backup, reviewer } } } });
                }}
              />
            </Box>
          </Stack>
          <LinkButton to={`/overview?from=${formatFromDate(from)}&to=${formatToDate(to)}`} sx={{ margin: 0 }}>
            {'Overview'}
          </LinkButton>
        </GanttTitle>
        {ganttTasksError ? <ErrorAlert error={ganttTasksError} sx={{ marginBottom: 1 }} /> : null}
        <GanttDataGrid
          ganttTasks={!owner && !backup && !reviewer ? [] : filteredGanttTasks}
          onTime={onTime}
          zoomLevel={zoomLevel}
          onTaskClick={setSelectedTask}
          loading={ganttTaskIsFetching}
          selectFilterMessage={!owner && !backup && !reviewer}
        />
        {selectedTask && turnbackList.data && (
          <GanttTaskDetailsDialog
            open={Boolean(selectedTask)}
            ganttTask={selectedTask}
            onClose={() => setSelectedTask(null)}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
