import { useMemo } from 'react';
import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { Subprocess } from '../entities/Subprocess';
import { GanttTask } from '../entities/Task';
import { useReadSubprocessListQuery } from '../services/subprocessApi';
import { emptyFilterValueOption } from '../utils/datagrid';

export function useSubprocessColDef(): GridColDef {
  const { data: subprocessList } = useReadSubprocessListQuery();

  const subprocessMap = useMemo(() => {
    const subprocessMap = new Map<Subprocess['id'], Subprocess>();
    if (subprocessList) {
      for (let i = 0; i < subprocessList.length; i++) {
        subprocessMap.set(subprocessList[i].id, subprocessList[i]);
      }
    }
    return subprocessMap;
  }, [subprocessList]);
  return useMemo(
    () => ({
      field: 'subprocess',
      headerName: 'Subprocess',
      type: 'singleSelect',
      valueOptions: [emptyFilterValueOption].concat(
        subprocessList?.map((subprocess) => ({
          value: subprocess.id,
          label: subprocess.name,
        })) ?? [],
      ),
      valueFormatter: (value: Subprocess['id']) => subprocessMap.get(value)?.name ?? '—',
      valueGetter: (value, row: GanttTask) => row.task.subprocessId ?? -1,
      renderCell: ({ value }: GridRenderCellParams<GridValidRowModel, Subprocess['id']>) =>
        value && subprocessMap.get(value)?.name,
      width: 140,
    }),
    [subprocessList, subprocessMap],
  );
}
