import { useMemo } from 'react';
import { Category } from '../entities/Category';
import { useReadCategoryListQuery } from '../services/categoryApi';

export function useCategoriesById() {
  const { data } = useReadCategoryListQuery();

  return useMemo(() => {
    const categoriesById = new Map<Category['id'], Category>();
    for (const category of data ?? []) {
      categoriesById.set(category.id, category);
    }
    return categoriesById;
  }, [data]);
}
