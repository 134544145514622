import { useMemo } from 'react';
import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { ScenarioLabel } from '../components/ScenarioLabel';
import { Scenario } from '../entities/Scenario';
import { GanttTask } from '../entities/Task';
import { useScenarioNames } from './useScenarioNames';
import { useScenariosById } from './useScenariosById';

export function useScenarioColDef(): GridColDef {
  const { scenarioNames } = useScenarioNames();
  const scenariosById = useScenariosById();

  return useMemo(() => {
    return {
      field: 'scenario',
      headerName: 'Scenario',
      type: 'singleSelect',
      width: 160,
      valueOptions: scenarioNames,
      renderCell: ({ row }: GridRenderCellParams<GridValidRowModel, Scenario['id']>) => {
        if (!row.task) {
          return '—';
        }

        const scenario = row.task.scenario;
        return scenario ? <ScenarioLabel scenario={scenario} /> : '—';
      },
      valueGetter: (value, row: GanttTask) =>
        row.task.scenarioId ? scenariosById.get(row.task.scenarioId)?.name : '—',
    };
  }, [scenarioNames, scenariosById]);
}
