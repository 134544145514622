import { useEffect, useMemo } from 'react';
import { Controller, UseFormWatch, UseFormSetValue, Control } from 'react-hook-form';
import { IntegerTextField } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AreaAutocomplete } from '../../../../../components/Form/AreaAutocomplete';
import { CategoryAutocomplete } from '../../../../../components/Form/CategoryAutocomplete';
import { DepartmentAutocomplete } from '../../../../../components/Form/DepartmentAutocomplete';
import { ProcessAutocomplete } from '../../../../../components/Form/ProcessAutocomplete';
import { ScenarioAutocomplete } from '../../../../../components/Form/ScenarioAutocomplete';
import { SubprocessAutocomplete } from '../../../../../components/Form/SubprocessAutocomplete';
import { UserAutocomplete } from '../../../../../components/Form/UserAutocomplete';
import { GridButton, GridButtonGroup } from '../../../../../components/GridButtonGroup';
import { EditingTask, TaskConfigurationForm } from '../../../../../entities/Task';
import { UserRoleName } from '../../../../../entities/User';
import { useAreasById } from '../../../../../hooks/useAreasById';
import { useCategoriesById } from '../../../../../hooks/useCategoriesById';
import { useDepartmentsById } from '../../../../../hooks/useDepartmentsById';
import { useProcessesById } from '../../../../../hooks/useProcessesById';
import { useScenariosById } from '../../../../../hooks/useScenariosById';

type TaskConfigurationFormContentProps = {
  task?: EditingTask;
  //
  watch: UseFormWatch<TaskConfigurationForm>;
  setValue: UseFormSetValue<TaskConfigurationForm>;
  control: Control<TaskConfigurationForm>;
};

export function TaskConfigurationFormContent(props: TaskConfigurationFormContentProps): JSX.Element {
  const { task, setValue, watch, control } = props;
  const { isAdmin, hasRole, username } = useAuth();

  const processId = watch('processId');
  const scenarioId = watch('scenarioId');
  const subprocessId = watch('subprocessId');
  const areaId = watch('areaId');
  const departmentId = watch('departmentId');
  const categoryId = watch('categoryId');
  const ownerUsername = watch('ownerUsername');
  const reviewerUsername = watch('reviewerUsername');

  const ownerChanged = useMemo(() => {
    return task?.owner === username && ownerUsername !== username;
  }, [ownerUsername, task?.owner, username]);
  const reviewerChanged = useMemo(() => {
    return task?.reviewer === username && reviewerUsername !== username;
  }, [reviewerUsername, task?.reviewer, username]);

  useEffect(() => {
    if (!isAdmin) {
      if (!ownerChanged && !reviewerChanged) {
        setValue('confirmUserChange', true);
      } else {
        setValue('confirmUserChange', false as unknown as true);
      }
    }
  }, [isAdmin, ownerChanged, reviewerChanged, setValue]);

  const processesById = useProcessesById();
  const areasById = useAreasById();
  const departmentsById = useDepartmentsById();
  const scenariosById = useScenariosById();
  const subprocessesById = useProcessesById();
  const categoriesById = useCategoriesById();

  const process = processesById.get(processId);
  const area = areasById.get(areaId);
  const department = departmentsById.get(departmentId ?? -1);
  const scenario = scenariosById.get(scenarioId ?? -1);
  const subprocess = subprocessesById.get(subprocessId ?? -1);
  const category = categoriesById.get(categoryId ?? -1);

  return (
    <>
      <Grid container columnSpacing={2} rowSpacing={1} paddingTop={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="description"
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Description"
                error={Boolean(error)}
                helperText={error?.message || ' '}
                fullWidth
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="processId"
            render={({ field, fieldState: { error } }) => (
              <ProcessAutocomplete
                error={Boolean(error)}
                helperText={error?.message}
                fullWidth
                {...field}
                value={process}
                disabled={false}
                processManager={hasRole(UserRoleName.MANAGER) && username ? username : undefined}
                onChange={(_, newProcess) => {
                  if (!newProcess || (processId && processId !== newProcess.id)) {
                    setValue('scenarioId', null as unknown as number);
                    setValue('categoryId', null as unknown as number);
                  }
                  field.onChange(newProcess?.id);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="subprocessId"
            render={({ field, fieldState: { error } }) => (
              <SubprocessAutocomplete
                error={Boolean(error)}
                helperText={error?.message ?? ' '}
                fullWidth
                {...field}
                value={subprocess}
                onChange={(_, subprocess) => field.onChange(subprocess?.id)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="areaId"
            render={({ field, fieldState: { error } }) => (
              <AreaAutocomplete
                error={Boolean(error)}
                helperText={error?.message ?? ' '}
                fullWidth
                {...field}
                value={area}
                onChange={(_, area) => field.onChange(area?.id)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="department"
            render={({ field, fieldState: { error } }) => (
              <DepartmentAutocomplete
                error={Boolean(error)}
                helperText={error?.message ?? ' '}
                fullWidth
                {...field}
                value={department}
                onChange={(_, department) => field.onChange(department?.id)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="scenarioId"
            render={({ field, fieldState: { error } }) => (
              <ScenarioAutocomplete
                error={Boolean(error)}
                helperText={error?.message}
                process={process}
                disabled={!processId}
                sx={{ minWidth: '20ch' }}
                fullWidth
                {...field}
                value={scenario}
                onChange={(_, scenario) => field.onChange(scenario?.id)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="categoryId"
            render={({ field, fieldState: { error } }) => (
              <CategoryAutocomplete
                error={Boolean(error)}
                helperText={error?.message}
                process={process}
                disabled={!processId}
                sx={{ minWidth: '20ch' }}
                fullWidth
                {...field}
                value={category}
                onChange={(_, category) => field.onChange(category?.id)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="documentUrl"
            render={({ field: { ...field }, fieldState: { error } }) => (
              <TextField
                label="Document link"
                fullWidth
                error={Boolean(error)}
                helperText={error?.message || ' '}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight={500} gutterBottom marginTop={1}>
            {'Task type'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="isDeliverable"
            render={({ field }) => (
              <GridButtonGroup sx={{ width: '100%' }} wrapAfter={2}>
                <GridButton onClick={() => field.onChange(false)} checked={field.value === false}>
                  {'Normal'}
                </GridButton>
                <GridButton onClick={() => field.onChange(true)} checked={field.value === true}>
                  {'Deliverable'}
                </GridButton>
              </GridButtonGroup>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight={500} gutterBottom marginTop={2}>
            {'Assignees'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="ownerUsername"
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <UserAutocomplete
                label="Owner"
                fullWidth
                error={Boolean(error)}
                onChange={(_, value) => onChange(value as string)}
                helperText={error?.message || ' '}
                disabled={!isAdmin}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="backupUsername"
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <UserAutocomplete
                label="Backup"
                fullWidth
                error={Boolean(error)}
                onChange={(_, value) => onChange(value as string)}
                helperText={error?.message || ' '}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="reviewerUsername"
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <UserAutocomplete
                label="Reviewer"
                fullWidth
                error={Boolean(error)}
                onChange={(_, value) => onChange(value as string)}
                helperText={error?.message || ' '}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {!isAdmin && (ownerChanged || reviewerChanged) ? (
            <Controller
              control={control}
              name="confirmUserChange"
              render={({ field: { value, ...field }, fieldState: { error } }) => (
                <Alert
                  severity="warning"
                  sx={{
                    alignItems: 'center',
                    marginTop: 1,
                    border: error ? '1px solid' : 'none',
                    borderColor: 'error.main',
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox {...field} checked={value} size="small" />}
                    label={
                      <span>
                        {`I understand that changing the ${
                          ownerChanged && reviewerChanged ? 'assignees' : ownerChanged ? 'Owner' : 'Reviewer'
                        } may result in loss of access to the current Task.`}
                      </span>
                    }
                    sx={{ marginLeft: 0.5, '.MuiTypography-root': { marginLeft: 1 } }}
                  />
                </Alert>
              )}
            />
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight={500} gutterBottom marginTop={1}>
            {'Journal'}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4} lg={4}>
          <Controller
            control={control}
            name="entryNumber"
            render={({ field: { ...field }, fieldState: { error } }) => (
              <IntegerTextField
                label="Journal entry"
                fullWidth
                error={Boolean(error)}
                helperText={error?.message || ' '}
                {...field}
                value={field.value || null}
                onChange={(_, value) => field.onChange(value ?? undefined)}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={4}>
          <Controller
            control={control}
            name="entryType"
            render={({ field: { ...field }, fieldState: { error } }) => (
              <TextField
                label="Entry type"
                fullWidth
                error={Boolean(error)}
                helperText={error?.message || ' '}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={4}>
          <Controller
            control={control}
            name="reversal"
            render={({ field: { ...field }, fieldState: { error } }) => (
              <TextField
                label="Reversal"
                fullWidth
                error={Boolean(error)}
                helperText={error?.message || ' '}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}
