import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MUIPremiumKey } from '@top-solution/microtecnica-mui';
import { CommonDataApiProvider, setAuthApiUrl, ZodLocalizationProvider } from '@top-solution/microtecnica-utils';
import { enGB } from 'date-fns/locale/en-GB';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { LicenseInfo } from '@mui/x-license';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import { App } from './App';
import { WorkingDaysProvider } from './hooks/useWorkingDays';
import { store } from './store';
import { theme } from './theme';

const appId = `${import.meta.env.VITE_APP_ID}`;
const appVersion = `${import.meta.env.VITE_VERSION}`;
const authApi = `${import.meta.env.VITE_AUTH_API}`;

// eslint-disable-next-line
console.log(`🚀 ${appId} (${appVersion}) 🚀`);

if (localStorage.getItem('enableDebugLogging') === 'true') {
  // eslint-disable-next-line
  console.log(`Disable debug console logging with "localStorage.removeItem('enableDebugLogging')"`);
} else {
  // eslint-disable-next-line
  console.log(`Enable debug console logging with "localStorage.setItem('enableDebugLogging', 'true')"`);
}

setAuthApiUrl(`${authApi}/api`);

// FIXME: use the one from microtecnica-mui after update
LicenseInfo.setLicenseKey(MUIPremiumKey);

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
  <StrictMode>
    <CommonDataApiProvider value={{ appId, authApi }}>
      <ZodLocalizationProvider />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
            <BrowserRouter>
              <HelmetProvider>
                <WorkingDaysProvider>
                  <App />
                </WorkingDaysProvider>
              </HelmetProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </CommonDataApiProvider>
  </StrictMode>,
);
