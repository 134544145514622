import React, { useContext, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { isAfter, isBefore } from 'date-fns';
import { GanttTask, TaskStatus } from '../entities/Task';
import { useScenariosById } from '../hooks/useScenariosById';
import { GanttTasksContext } from './GanttTasksProvider';

const FilteredGanttTasksContext = React.createContext({
  filteredGanttTasks: [] as GanttTask[],
  error: undefined as FetchBaseQueryError | SerializedError | undefined,
  isLoading: false as boolean,
  oldestOpenTask: undefined as GanttTask | undefined,
});

export function FilteredGanttTasksProvider(props: { children: React.ReactNode }) {
  const scenariosById = useScenariosById();
  const { ganttTasks, error, isLoading } = useContext(GanttTasksContext);
  const params = useParams<{
    processId?: string;
    scenarioName?: string;
    subprocessId: string;
    ownerUserName: string;
  }>();
  const [qs] = useSearchParams();

  const from = useMemo(() => (qs.has('from') ? new Date(qs.get('from') as string) : undefined), [qs]);
  const to = useMemo(() => (qs.has('to') ? new Date(qs.get('to') as string) : undefined), [qs]);

  const filteredGanttTasks = useMemo(() => {
    if (!from || !to) {
      return [];
    }

    return ganttTasks.filter((gt) => {
      if (from && isBefore(gt.date, from)) {
        return false;
      }
      if (to && isAfter(gt.date, to)) {
        return false;
      }

      if (params.processId !== undefined && Number(params.processId) !== gt.task.processId) {
        return false;
      }
      if (
        params.scenarioName !== undefined &&
        gt.task.scenarioId &&
        params.scenarioName !== scenariosById.get(gt.task.scenarioId)?.name
      ) {
        return false;
      }
      if (params.subprocessId !== undefined && Number(params.subprocessId) !== gt.task.subprocessId) {
        return false;
      }
      return true;
    });
  }, [from, ganttTasks, params.processId, params.scenarioName, params.subprocessId, scenariosById, to]);

  const oldestOpenTask = useMemo(() => {
    return ganttTasks.reduce(
      (min, ganttTask) =>
        ganttTask.status === TaskStatus.Open && isBefore(ganttTask.date, min.date) ? ganttTask : min,
      ganttTasks[0],
    );
  }, [ganttTasks]);

  return (
    <FilteredGanttTasksContext.Provider value={{ filteredGanttTasks, error, isLoading, oldestOpenTask }}>
      {props.children}
    </FilteredGanttTasksContext.Provider>
  );
}

export function useFilteredGanttTasks() {
  return useContext(FilteredGanttTasksContext);
}
