import { useMemo } from 'react';
import { User } from '../entities/User';
import { useReadUserListQuery } from '../services/userApi';

export function useUsersByUsername() {
  const { data } = useReadUserListQuery();

  return useMemo(() => {
    const usersByUsername = new Map<User['username'], User>();
    for (const user of data ?? []) {
      usersByUsername.set(user.username, user);
    }
    return usersByUsername;
  }, [data]);
}
