import { useMemo } from 'react';
import {
  add,
  differenceInDays,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  getDay,
  isAfter,
  isBefore,
  isSameDay,
  startOfDay,
  sub,
} from 'date-fns';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useFilteredGanttTasks } from '../../components/FilteredGanttTasks';
import {
  FromTo,
  PeopleLoadRow,
  PeopleLoadMetric,
  PeopleLoadTimeSample,
  UserPeriodMetrics,
} from '../../entities/PeopleLoad';
import { GanttTask, TaskStatus } from '../../entities/Task';
import { User, UserRoleName } from '../../entities/User';
import { Timetable, createEmptyTimetable } from '../../entities/UserTimetable';
import { useTaskScheduling } from '../../hooks/useTaskScheduling';
import { useUsersColDefs } from '../../hooks/useUsersColDefs';
import { IsWorkingDay } from '../../hooks/useWorkingDays';
import { useReadUserListQuery } from '../../services/userApi';
import { weekDayWorkingSeconds } from '../../utils/users';
import { FromToGridColDef, usePeopleLoadColDef } from './usePeopleLoadColDef';

const averageRowUserPeriodMetrics: Omit<UserPeriodMetrics, 'period' | 'metrics'> = {
  actualTasksDuration: {},
  completedTasks: [],
  onTimeTasks: [],
  scheduledTasksDuration: {},
  user: {
    id: -1,
    username: 'ÿÿÿÿÿÿÿÿ',
    role: UserRoleName.ADMIN,
    timetable: [],
  },
  userTimetable: [],
  tasks: [],
};

function isBetween(date: Date, dateToCompareAfter: Date, dateToCompareBefore: Date) {
  return isAfter(date, dateToCompareAfter) && isAfter(date, dateToCompareBefore);
}

function getTimeSamplePeriods(
  timeSample: PeopleLoadTimeSample,
  from: Date,
  to: Date,
  isWorkingDay: IsWorkingDay,
): FromTo[] {
  if (from && to) {
    const columns: FromTo[] = [];

    if (timeSample === PeopleLoadTimeSample.Daily) {
      const dayColumns = differenceInDays(to, from);
      for (let dayIndex = 0; dayIndex < dayColumns; dayIndex++) {
        const date = add(from, { days: dayIndex });
        if (isWorkingDay(date)) {
          columns.push({ from: date, to: add(date, { days: 1 }) });
        }
      }
      return columns;
    }

    if (timeSample === PeopleLoadTimeSample.Weekly) {
      const weekColumns = differenceInWeeks(to, from);
      for (let weekIndex = 0; weekIndex < weekColumns; weekIndex++) {
        const date = add(from, { weeks: weekIndex });
        columns.push({ from: date, to: add(date, { weeks: 1 }) });
      }
      return columns;
    }

    if (timeSample === PeopleLoadTimeSample.Monthly) {
      const monthColumns = differenceInMonths(to, from);
      for (let monthIndex = 0; monthIndex < monthColumns; monthIndex++) {
        const date = add(from, { months: monthIndex });
        columns.push({ from: date, to: add(date, { months: 1 }) });
      }
      return columns;
    }
  }
  return [];
}

function tasksDuration(ganttTask: GanttTask): [number, number] {
  const { completedTask, date, task } = ganttTask;

  const scheduledTime = task.duration;

  if (!completedTask || !completedTask.closedDate) {
    return [scheduledTime, scheduledTime];
  }

  const closedDate = new Date(completedTask.closedDate);
  const scheduledTaskEnd = new Date(date);
  const scheduledTaskStart = sub(scheduledTaskEnd, { seconds: task.duration });

  const startOfScheduledDay = startOfDay(scheduledTaskEnd);
  const endOfScheduledDay = startOfDay(scheduledTaskEnd);

  //   Se l'attività viene chiusa prima della DATA programmata (di conseguenza anche ORA) di inizio: il tempo impiegato per l'attività è quello previsto in fase di programmazione dell'attività. Secondo l'esempio l'attività è stata chiusa prima del giorno 31 si suppone fino al giorno 30 compreso indifferentemente dall'orario e dal tempo impiegato si prende in considerazione il tempo programmato quindi 2 ore.
  if (isBefore(closedDate, startOfScheduledDay)) {
    return [scheduledTime, scheduledTime];
  }

  //   Se l'attività viene chiusa nella stessa DATA programmata (quindi il giorno 31/01/2024):
  if (isSameDay(closedDate, scheduledTaskEnd)) {
    //  Se l'attività viene chiusa tra le 9 e le 11 si prende il considerazione il tempo effettivo. Supponendo di aver chiuso l'attività alle 10 la durata di quest'ultima è di 1 ora
    if (isBetween(closedDate, scheduledTaskStart, scheduledTaskEnd)) {
      return [Math.abs(differenceInSeconds(scheduledTaskStart, closedDate)), scheduledTime];
    }

    //  Se l'attività viene chiusa dopo l'orario previsto di chiusura si prende in considerazione il tempo effettivo. Supponendo di aver chiuso l'attività alle ore 12 la durata di quest'ultima è di 3 ore
    if (isAfter(closedDate, scheduledTaskEnd)) {
      return [Math.abs(differenceInSeconds(scheduledTaskStart, closedDate)), scheduledTime];
    }

    //  Se l'attività viene chiusa prima delle 9 si prende in considerazione il tempo programmato per l'attività quindi 2 ore
    if (isBefore(closedDate, scheduledTaskStart)) {
      return [scheduledTime, scheduledTime];
    }
  }

  //   Se l'attività viene chiusa oltre la DATA programmata (di conseguenza anche ORA) di inizio: il tempo impiegato per l'attività è quello previsto in fase di programmazione dell'attività. Secondo l'esempio l'attività è stata chiusa oltre il giorno 31/01/2024 quindi si suppone il giorno 01/02/2024 indifferentemente dall'orario e dal tempo impiegato si prende in considerazione il tempo programmato quindi 2 ore.
  if (isAfter(closedDate, endOfScheduledDay)) {
    return [scheduledTime, scheduledTime];
  }

  return [0, scheduledTime];
}

export function usePeopleLoadTable(timeSample: PeopleLoadTimeSample, metric: PeopleLoadMetric, from: Date, to: Date) {
  const { ownerColumn } = useUsersColDefs<PeopleLoadRow>();
  const peopleLoadColDef = usePeopleLoadColDef();
  const { isWorkingDay } = useTaskScheduling(from.getFullYear(), to.getFullYear() + 1);
  const readUserListQuery = useReadUserListQuery();
  // const useGanttTasksParams: UseGanttTasksParams = useMemo(
  //   () => ({
  //     from: from,
  //     to: to,
  //     skip: false,
  //     disableMonthFiltering: true,
  //   }),
  //   [from, to],
  // );
  const { filteredGanttTasks } = useFilteredGanttTasks();
  const timeSamplePeriodColumns = useMemo(
    () => getTimeSamplePeriods(timeSample, from, to, isWorkingDay),
    [from, isWorkingDay, timeSample, to],
  );

  const [users, userList, userTimetables] = useMemo(() => {
    const userTimetables: Record<string, Timetable | null> = {};
    const users: Record<string, User> = {};
    if (readUserListQuery.data) {
      for (const user of readUserListQuery.data) {
        if (user.role !== UserRoleName.GUEST) {
          userTimetables[user.username] = (user.timetable as unknown as Timetable) ?? createEmptyTimetable();
          users[user.username] = user;
        }
      }
    }
    return [users, readUserListQuery.data, userTimetables];
  }, [readUserListQuery.data]);

  const columns = useMemo(() => {
    const peopleLoadOwnerColumn = ownerColumn((row) => row.user);
    const columns: (GridColDef | FromToGridColDef)[] = [
      {
        ...peopleLoadOwnerColumn,
        field: 'name',
        headerName: 'User',
        width: 220,
        valueGetter: (value, row: PeopleLoadRow, column, apiRef) => {
          if (row.id === 'average') {
            return 'zzzzzz';
          }
          return peopleLoadOwnerColumn.valueGetter?.(value, row, column, apiRef);
        },
        renderCell: (args) => {
          if (args.row.id === 'average') {
            return <Typography fontWeight={700}>{'Average'}</Typography>;
          }
          return peopleLoadOwnerColumn.renderCell?.(args);
        },
      } as GridColDef,
    ];
    for (const timeSamplePeriodColumn of timeSamplePeriodColumns) {
      columns.push(peopleLoadColDef(timeSamplePeriodColumn.from, metric, timeSample));
    }
    return columns;
  }, [ownerColumn, peopleLoadColDef, metric, timeSample, timeSamplePeriodColumns]);

  const cells = useMemo(() => {
    const cells: Record<string, Record<string, UserPeriodMetrics>> = {};

    if (!userList) {
      return cells;
    }

    // Step I: create cells
    for (const { username } of userList) {
      cells[username] = {};

      for (const { from, to } of timeSamplePeriodColumns) {
        cells[username][from.toISOString()] = {
          period: {
            from: from,
            to: to,
          },
          user: users[username],
          userTimetable: userTimetables[username] ?? createEmptyTimetable(),
          tasks: [],
          onTimeTasks: [],
          completedTasks: [],
          actualTasksDuration: {},
          scheduledTasksDuration: {},
          metrics: {
            userWorkingSeconds: 0,
            tasksActualDuration: 0,
            tasksScheduledDuration: 0,
            load: 0,
            onTime: 0,
          },
        };
      }
    }

    // Step II: iterate all tasks, assigning them to the right user/column match
    for (const ganttTask of filteredGanttTasks) {
      if (!isAfter(new Date(ganttTask.date), from) || !isBefore(new Date(ganttTask.date), to)) {
        continue;
      }

      if (!ganttTask.task.owner) {
        continue;
      }

      const username = ganttTask.task.owner;

      const userRow = cells[username];

      if (!userRow) {
        // eslint-disable-next-line no-console
        console.warn(`Found a task assigned to ${username}, but the user was not found in users list`, userList);
        continue;
      }

      const taskDate = ganttTask.date;

      let fromColumn: Date | undefined = undefined;
      let toColumn: Date | undefined = undefined;

      // Find the period corresponding to the task planned date
      for (const { from, to } of timeSamplePeriodColumns) {
        if (isAfter(new Date(taskDate), from) && isBefore(new Date(taskDate), to)) {
          fromColumn = from;
          toColumn = to;
          break;
        }
      }

      if (!fromColumn || !toColumn) {
        continue;
      }

      const userPeriodMetrics: UserPeriodMetrics = userRow[fromColumn.toISOString()];

      if (ganttTask.status !== TaskStatus.NotApplicable) {
        userPeriodMetrics.tasks.push(ganttTask);
        const [actualDuration, scheduledDuration] = tasksDuration(ganttTask);
        userPeriodMetrics.actualTasksDuration[ganttTask.task.id] = actualDuration;
        userPeriodMetrics.scheduledTasksDuration[ganttTask.task.id] = scheduledDuration;

        if (ganttTask.status === TaskStatus.Late || ganttTask.status === TaskStatus.OnTime) {
          userPeriodMetrics.completedTasks.push(ganttTask);
        }

        if (ganttTask.status === TaskStatus.OnTime) {
          userPeriodMetrics.onTimeTasks.push(ganttTask);
        }

        // userPersonLoadByDay[fromColumn.toISOString()] = userPeriodMetrics;
        // cells[username] = userPersonLoadByDay;
      }
      // console.log(personLoad);
    }

    // Step III: calculate metrics in the table
    for (const [username, columns] of Object.entries(cells)) {
      for (const [_, userPeriodMetric] of Object.entries(columns)) {
        const timetable = userTimetables[username];

        if (timetable) {
          for (let i = 0; i < differenceInDays(userPeriodMetric.period.to, userPeriodMetric.period.from); i++) {
            const day = getDay(add(userPeriodMetric.period.from, { days: i }));
            const dayTimetable = timetable[day];

            if (dayTimetable) {
              userPeriodMetric.metrics.userWorkingSeconds =
                userPeriodMetric.metrics.userWorkingSeconds + weekDayWorkingSeconds(dayTimetable);

              userPeriodMetric.metrics.tasksActualDuration = Object.values(userPeriodMetric.actualTasksDuration).reduce(
                (total, t) => t + total,
                0,
              );
              userPeriodMetric.metrics.tasksScheduledDuration = Object.values(
                userPeriodMetric.scheduledTasksDuration,
              ).reduce((total, t) => t + total, 0);
            }

            if (
              userPeriodMetric.metrics.tasksActualDuration === 0 ||
              userPeriodMetric.metrics.userWorkingSeconds === 0
            ) {
              userPeriodMetric.metrics.load = 0;
            } else {
              userPeriodMetric.metrics.load =
                userPeriodMetric.metrics.tasksActualDuration / userPeriodMetric.metrics.userWorkingSeconds;
            }

            if (userPeriodMetric.onTimeTasks.length === 0 || userPeriodMetric.completedTasks.length === 0) {
              userPeriodMetric.metrics.onTime = 0;
            } else {
              userPeriodMetric.metrics.onTime =
                userPeriodMetric.onTimeTasks.length / userPeriodMetric.completedTasks.length;
            }
          }
        }
      }
    }

    return cells;
  }, [filteredGanttTasks, from, timeSamplePeriodColumns, to, userList, userTimetables, users]);

  const [rows, averageRow]: [PeopleLoadRow[], PeopleLoadRow] = useMemo(() => {
    const onTimeSums: Record<string, number> = {};
    const onTimeCounts: Record<string, number> = {};
    const loadSums: Record<string, number> = {};
    const loadCounts: Record<string, number> = {};

    const rows: PeopleLoadRow[] = [];
    for (const [username, columns] of Object.entries(cells)) {
      const user: User = users[username];

      if (!Object.values(columns).find((c) => c.tasks.length)) {
        continue;
      }

      if (Object.values(columns)[0] && user) {
        rows.push({ id: username, user: Object.values(columns)[0].user, periods: columns });
        for (const [period, column] of Object.entries(columns)) {
          if (column.metrics.onTime) {
            onTimeSums[period] = (onTimeSums[period] ?? 0) + column.metrics.onTime;
            onTimeCounts[period] = (onTimeCounts[period] ?? 0) + 1;
          }
          if (column.metrics.load) {
            loadSums[period] = (loadSums[period] ?? 0) + column.metrics.load;
            loadCounts[period] = (loadCounts[period] ?? 0) + 1;
          }
        }
      }
    }

    const avgRow: PeopleLoadRow = {
      id: 'average',
      user: averageRowUserPeriodMetrics.user,
      periods: {},
    };
    for (const column of timeSamplePeriodColumns) {
      const onTimeSum = onTimeSums[column.from.toISOString()];
      const loadSum = loadSums[column.from.toISOString()];

      avgRow.periods[column.from.toISOString()] = {
        ...averageRowUserPeriodMetrics,
        period: column,
        metrics: {
          onTime: onTimeSum > 0 ? onTimeSum / (onTimeCounts[column.from.toISOString()] ?? 1) : 0,
          load: loadSum > 0 ? loadSum / (loadCounts[column.from.toISOString()] ?? 1) : 0,
          tasksActualDuration: 0,
          tasksScheduledDuration: 0,
          userWorkingSeconds: 0,
        },
      };
    }

    rows.push(avgRow);

    return [rows, avgRow];
  }, [cells, timeSamplePeriodColumns, users]);

  return useMemo(
    () => ({
      columns,
      rows,
      averageRow,
      cells,
    }),
    [averageRow, cells, columns, rows],
  );
}
