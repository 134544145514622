import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authSliceReducer, commonDataApi } from '@top-solution/microtecnica-utils';
import { api } from '../services/baseApi';
import { notificationsMiddleware } from './listeners/notificationsMiddleware';

export const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    [api.reducerPath]: api.reducer,
    [commonDataApi.reducerPath]: commonDataApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, commonDataApi.middleware, notificationsMiddleware.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
