import { useMemo } from 'react';
import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { Category } from '../entities/Category';
import { GanttTask } from '../entities/Task';
import { useReadCategoryListQuery } from '../services/categoryApi';
import { useCategoriesById } from './useCategoriesById';

export function useCategoryColDef(): GridColDef {
  const { data: categoryList } = useReadCategoryListQuery();

  const categoriesById = useCategoriesById();

  const categoryOptions = useMemo(() => {
    const options = new Set<string>();
    if (categoryList) {
      for (const category of categoryList) {
        options.add(category.name);
      }
    }
    return Array.from(options);
  }, [categoryList]);

  return useMemo(
    () => ({
      field: 'category',
      headerName: 'Category',
      type: 'singleSelect',
      valueOptions: categoryOptions,
      renderCell: ({ value }: GridRenderCellParams<GridValidRowModel, Category['id']>) => value ?? '—',
      valueGetter: (value, row: GanttTask) =>
        row.task.categoryId ? categoriesById.get(row.task.categoryId)?.name : '',
      width: 80,
    }),
    [categoriesById, categoryOptions],
  );
}
