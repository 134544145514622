import { useMemo } from 'react';
import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { Department } from '../entities/Department';
import { GanttTask } from '../entities/Task';
import { useReadDepartmentListQuery } from '../services/departmentApi';

export function useDepartmentColDef(): GridColDef {
  const { data: departmentList } = useReadDepartmentListQuery();

  const departmentMap = useMemo(() => {
    const departmentMap = new Map<Department['id'], Department>();
    if (departmentList) {
      for (let i = 0; i < departmentList.length; i++) {
        departmentMap.set(departmentList[i].id, departmentList[i]);
      }
    }
    return departmentMap;
  }, [departmentList]);

  return useMemo(
    () => ({
      field: 'department',
      headerName: 'Department',
      type: 'singleSelect',
      valueOptions: departmentList?.map((department) => ({ label: department.name, value: department.id })),
      valueFormatter: (value: Department['id']) => departmentMap.get(value)?.name,
      valueGetter: (value, row: GanttTask) => row.task.departmentId,
      renderCell: ({ value }: GridRenderCellParams<GridValidRowModel, Department['id']>) =>
        value && departmentMap.get(value)?.name,
    }),
    [departmentList, departmentMap],
  );
}
