import { useMemo } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { DataGridWrapper, ErrorAlert } from '@top-solution/microtecnica-mui';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridPinnedColumnFields,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import { PlusIcon, MagnifyIcon } from '../../../components/Icons';
import { Layout } from '../../../components/Layout';
import { PAGE_TITLE_OFFSET_HEIGHT, PageTitle } from '../../../components/PageTitle';
import { ScenarioChip } from '../../../components/ScenarioChip';
import { StyledDataGrid } from '../../../components/StyledDataGrid';
import { Process } from '../../../entities/Process';
import { Scenario } from '../../../entities/Scenario';
import { User } from '../../../entities/User';
import { usePlannerConfig } from '../../../hooks/usePlannerConfig';
import { useReadProcessListQuery } from '../../../services/processApi';
import { stripedGetRowClassName } from '../../../utils/datagrid';
import { printUserName } from '../../../utils/users';
import { ProcessEditPage } from './ProcessEditPage';

const title = 'Manage Processes';
const breadcrumbs = [{ title }];
const pinnedColumnFields: GridPinnedColumnFields = { right: ['actions'] };

export function ProcessList(): JSX.Element {
  const processList = useReadProcessListQuery();
  const navigate = useNavigate();
  const { datagridProps, datagridInitialState } = usePlannerConfig();

  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'shortName',
        headerName: 'Short  name',
        minWidth: 100,
      },
      {
        field: 'scenarioList',
        headerName: 'Scenario',
        minWidth: 300,
        flex: 1,
        valueFormatter: (value: Scenario[]) =>
          Array.isArray(value) ? value.map((scenario) => scenario.name).join(', ') : value,
        renderCell: ({ row }: GridRenderCellParams<Process>) =>
          row.scenarioList.map((scenario) => <ScenarioChip key={scenario.id} scenario={scenario} />),
      },
      {
        field: 'categoryList',
        headerName: 'Category',
        minWidth: 350,
        flex: 1,
        valueFormatter: (value: Scenario[]) =>
          Array.isArray(value) ? value.map((category) => category.name).join(', ') : value,
        renderCell: ({ row }: GridRenderCellParams<Process>) =>
          row.categoryList.map((category) => <Chip key={category.name} label={category.name} size="small" />),
      },
      {
        field: 'managerList',
        headerName: 'Manager',
        minWidth: 350,
        flex: 1,
        valueFormatter: (value: User[]) =>
          Array.isArray(value) ? value.map((user) => printUserName(user)).join(', ') : value,
        renderCell: ({ row }: GridRenderCellParams<Process>) =>
          row.managerList.map((user) => <Chip key={user.id} label={printUserName(user)} size="small" />),
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 60,
        renderHeader: () => (
          <Tooltip title={title} placement="left">
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`./new`);
              }}
            >
              <PlusIcon />
            </IconButton>
          </Tooltip>
        ),
        getActions: ({ row }: GridRowParams<Process>) => [
          <GridActionsCellItem
            icon={<MagnifyIcon />}
            key="edit"
            label="Edit process"
            onClick={() => {
              // setProcessToEdit(row);
              navigate(`./${row.id}`);
            }}
          />,
        ],
      },
    ],
    [navigate],
  );

  const initialState = datagridInitialState((config) => config.pages?.processes?.datagrid);

  return (
    <Routes>
      <Route
        index
        element={
          <Layout
            title={title}
            breadcrumbs={breadcrumbs}
            maxWidth={false}
            sx={{ '.MuiDataGrid-cell .MuiChip-root': { marginRight: 0.125 } }}
          >
            <PageTitle title={title} />
            {processList.error ? <ErrorAlert error={processList.error} /> : null}
            <Stack direction="column" width="100%" gap={1}>
              <Box sx={{ flex: '1 1 0', minWidth: 0 }}>
                <DataGridWrapper offsetHeight={PAGE_TITLE_OFFSET_HEIGHT}>
                  <StyledDataGrid
                    initialState={initialState}
                    columns={columns}
                    pinnedColumns={pinnedColumnFields}
                    rows={processList.data ?? []}
                    loading={processList.isFetching}
                    disableRowSelectionOnClick={false}
                    disableAggregation
                    disableRowGrouping
                    onRowClick={(params) => navigate(`./${params.id}`)}
                    getRowClassName={stripedGetRowClassName}
                    {...datagridProps((datagridConfig) => ({
                      pages: { processes: { datagrid: datagridConfig } },
                    }))}
                    sx={{
                      '.MuiDataGrid-row': { cursor: 'pointer' },
                    }}
                  />
                </DataGridWrapper>
              </Box>
            </Stack>
          </Layout>
        }
      />
      <Route path="/:id" element={<ProcessEditPage />} />
    </Routes>
  );
}
