import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { GanttTask } from '../entities/Task';
import { User } from '../entities/User';
import { useGanttTasks } from '../hooks/useGanttTasks';
import { useUsersByUsername } from '../hooks/useUsersByUsername';
import { useReadCompletedTaskListQuery, useReadTaskListQuery } from '../services/taskApi';
import { useReadUserListQuery } from '../services/userApi';
import { sendNotifications } from '../store/listeners/notificationsMiddleware';
import { FullPageProgress } from './Progress';

export const GanttTasksContext = React.createContext({
  ganttTasks: [] as GanttTask[],
  error: undefined as FetchBaseQueryError | SerializedError | undefined,
  isLoading: false as boolean,
  isFetching: false as boolean,
  refetch: () => {
    //
  },
  fulfilledTimeStamp: undefined as number | undefined,
});

function InternalGanttTasksProvider(props: { children: React.ReactNode }) {
  const dispatch = useDispatch();
  const { ganttTasks, fulfilledTimeStamp, error, isLoading, isFetching, refetch } = useGanttTasks();
  const usersByUsername = useUsersByUsername();

  const ganttTasksRef = useRef<GanttTask[] | undefined>(undefined);
  const usersByUsernameRef = useRef<Record<string, User> | undefined>(undefined);

  useEffect(() => {
    if (fulfilledTimeStamp && ganttTasks) {
      ganttTasksRef.current = ganttTasks;
    }
    if (usersByUsername) {
      const usersByUsernameRecord: Record<string, User> = {};
      for (const [key, value] of usersByUsername) {
        usersByUsernameRecord[key] = value;
      }
      usersByUsernameRef.current = usersByUsernameRecord;
    }
  }, [fulfilledTimeStamp, ganttTasks, usersByUsername]);

  useEffect(() => {
    let mailSenderInterval: number;
    const mailSenderTimeout = setTimeout(() => {
      mailSenderInterval = setInterval(
        () => {
          if (ganttTasksRef.current && usersByUsernameRef.current) {
            dispatch(sendNotifications(ganttTasksRef.current, usersByUsernameRef.current));
          }
        },
        10 * 60 * 1000,
      );
      if (ganttTasksRef.current && usersByUsernameRef.current) {
        dispatch(sendNotifications(ganttTasksRef.current, usersByUsernameRef.current));
      }
    }, 10 * 1000);

    return () => {
      if (mailSenderTimeout) {
        clearTimeout(mailSenderTimeout);
      }
      if (mailSenderInterval) {
        clearInterval(mailSenderInterval);
      }
    };
  }, [dispatch]);

  if (isLoading) {
    return <FullPageProgress />;
  }

  return (
    <GanttTasksContext.Provider value={{ ganttTasks, fulfilledTimeStamp, error, isLoading, isFetching, refetch }}>
      {props.children}
    </GanttTasksContext.Provider>
  );
}

const pollingInterval = 120 * 1000;

export const GanttTasksProvider = function GanttTasksRequirements(props: { children: React.ReactNode }) {
  useReadUserListQuery();
  useReadTaskListQuery({ limit: 0, offset: 0 }, { pollingInterval });
  useReadCompletedTaskListQuery(undefined, { pollingInterval });

  return <InternalGanttTasksProvider>{props.children}</InternalGanttTasksProvider>;
};
