import { createListenerMiddleware, createAction } from '@reduxjs/toolkit';
import { isBefore } from 'date-fns';
import { GanttTask, NotificationsGanttTask, TaskStatus } from '../../entities/Task';
import { User } from '../../entities/User';
import { notificationApi } from '../../services/notificationApi';

export const sendNotifications = createAction(
  'notifications/send',
  function prepare(ganttTasks: GanttTask[], usersByUsername: Record<string, User>) {
    return {
      payload: {
        ganttTasks,
        usersByUsername,
      },
    };
  },
);

export const notificationsMiddleware = createListenerMiddleware();

notificationsMiddleware.startListening({
  actionCreator: sendNotifications,
  effect: async (action, listenerApi) => {
    const ganttTasks = action.payload.ganttTasks;

    const lateTasks: NotificationsGanttTask[] = ganttTasks
      .filter(
        (gt) =>
          (gt.status === TaskStatus.Open || gt.status === TaskStatus.Overdue) &&
          isBefore(new Date(gt.date), new Date()),
      )
      .map((lateTask) => ({
        ...lateTask,
        task: {
          ...lateTask.task,
          owner: action.payload.usersByUsername[lateTask.task.owner],
          reviewer: action.payload.usersByUsername[lateTask.task.reviewer],
        },
      }))
      .filter((lateTask) => lateTask.task.owner && lateTask.task.reviewer);

    // eslint-disable-next-line no-console
    console.log('✉️ Sending notifications, late tasks: ', lateTasks);

    listenerApi.dispatch(
      notificationApi.endpoints.createNotifications.initiate({
        tasks: lateTasks,
      }),
    );
  },
});
