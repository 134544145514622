import { useMemo } from 'react';
import { Subprocess } from '../entities/Subprocess';
import { useReadSubprocessListQuery } from '../services/subprocessApi';

export function useSubprocessesById() {
  const { data } = useReadSubprocessListQuery();

  return useMemo(() => {
    const processesById = new Map<Subprocess['id'], Subprocess>();
    for (const subprocess of data ?? []) {
      processesById.set(subprocess.id, subprocess);
    }
    return processesById;
  }, [data]);
}
