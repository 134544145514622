import { NotificationsGanttTask } from '../entities/Task';
import { api, TAG_TYPES } from './baseApi';

const url = 'notifications';

export const notificationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createNotifications: builder.mutation<void, { tasks: Array<NotificationsGanttTask> }>({
      query: (body) => ({ url: `${url}`, method: 'POST', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.NOTIFICATIONS, id: 'LIST' }],
    }),
  }),
});

export const { useCreateNotificationsMutation } = notificationApi;
