import { useMemo } from 'react';
import { Department } from '../entities/Department';
import { useReadDepartmentListQuery } from '../services/departmentApi';

export function useDepartmentsById() {
  const { data } = useReadDepartmentListQuery();

  return useMemo(() => {
    const processesById = new Map<Department['id'], Department>();
    for (const department of data ?? []) {
      processesById.set(department.id, department);
    }
    return processesById;
  }, [data]);
}
