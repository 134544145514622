import { useMemo } from 'react';
import { Scenario } from '../entities/Scenario';
import { useReadScenarioListQuery } from '../services/scenarioApi';

export function useScenariosById() {
  const { data } = useReadScenarioListQuery();

  return useMemo(() => {
    const scenariosById = new Map<Scenario['id'], Scenario>();
    for (const scenario of data ?? []) {
      scenariosById.set(scenario.id, scenario);
    }
    return scenariosById;
  }, [data]);
}
