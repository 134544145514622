import { WorkingDay, WorkingDaySchema } from '../entities/WorkingDay';
import { api, TAG_TYPES } from './baseApi';

const url = 'working-days';

const workingDayApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readWorkingDayList: builder.query<WorkingDay[], void>({
      query: () => ({
        url,
        params: { startDate: `2022-01-01`, endDate: `${new Date().getFullYear() + 10}-12-31` },
      }),
      transformResponse: (data) => WorkingDaySchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.WORKING_DAY, id: `LIST` }],
    }),
    updateWorkingDay: builder.mutation<void, { date: string; isWorkingDay: boolean }>({
      query: ({ date, isWorkingDay }) => ({
        url: `${url}/${date}`,
        method: 'PUT',
        body: { isWorkingDay },
      }),
      invalidatesTags: (request, response, { date }) => [
        { type: TAG_TYPES.WORKING_DAY, id: `LIST_${date.substring(0, 4)}` },
      ],
    }),
  }),
  overrideExisting: false,
});

export const { useReadWorkingDayListQuery, useUpdateWorkingDayMutation } = workingDayApi;
