import { useMemo } from 'react';
import { Process } from '../entities/Process';
import { useReadProcessListQuery } from '../services/processApi';

export function useProcessesById() {
  const { data } = useReadProcessListQuery();

  return useMemo(() => {
    const processesById = new Map<Process['id'], Process>();
    for (const process of data ?? []) {
      processesById.set(process.id, process);
    }
    return processesById;
  }, [data]);
}
