import { memo } from 'react';
import Box from '@mui/material/Box';
import { darken, lighten } from '@mui/material/styles';
import { GanttTask } from '../../entities/Task';
import { useScenariosById } from '../../hooks/useScenariosById';

type GanttTaskBarProps = {
  task: GanttTask;
  hour: number;
  onClick?: (task: GanttTask) => void;
};

const DEFAULT_DURATION = 5;

function GanttTaskBarComponent(props: GanttTaskBarProps): JSX.Element {
  const { task, hour, onClick } = props;

  const scenariosById = useScenariosById();

  let taskBox, machineBox;

  const startHour = task.task.dueHour ? task.task.dueHour - (task.task.duration || DEFAULT_DURATION) * 60 : undefined;
  const machineStartHour = task.task.dueHourMachine
    ? task.task.dueHourMachine - (task.task.durationMachine || DEFAULT_DURATION) * 60
    : undefined;

  const overlap =
    startHour &&
    machineStartHour &&
    task.task.dueHourMachine &&
    task.task.dueHourMachine > -1 &&
    task.task.dueHour &&
    task.task.dueHour > -1 &&
    ((startHour < task.task.dueHourMachine && task.task.dueHour > machineStartHour) ||
      (machineStartHour < task.task.dueHour && task.task.dueHourMachine > startHour));

  if (task.task.dueHourMachine && task.task.dueHourMachine >= hour && task.task.dueHourMachine < hour + 1) {
    machineBox = (
      <Box
        sx={{
          borderRadius: 1,
          position: 'absolute',
          top: 1,
          bottom: overlap ? '40%' : 1,
          right: `${100 - 100 * (task.task.dueHourMachine / 3600 - hour)}%`,
          width: `${
            (100 *
              (task.task.durationMachine && task.task.durationMachine > 0
                ? task.task.durationMachine
                : DEFAULT_DURATION)) /
            60
          }%`,
          bgcolor: 'grey.400',
          zIndex: 1,
        }}
      ></Box>
    );
  }

  const taskScenario = task.task.scenarioId ? scenariosById.get(task.task.scenarioId) : undefined;

  if (task.task.dueHour && task.task.dueHour >= hour && task.task.dueHour < hour + 3600) {
    taskBox = (
      <Box
        className="test"
        component={task.lunchBreak ? 'div' : 'button'}
        onClick={!task.lunchBreak && onClick ? () => onClick(task) : undefined}
        sx={(theme) => ({
          border: 0,
          borderRadius: 1,
          padding: 0,
          position: 'absolute',
          top: overlap ? '20%' : 1,
          bottom: 1,
          right: `${100 - ((task.task.dueHour ? task.task.dueHour : hour) - hour) / 36}%`,
          width: `${Math.max(task.task.duration > 0 ? task.task.duration : DEFAULT_DURATION) / 36}%`,
          minWidth: 8,
          background: task.lunchBreak
            ? `repeating-linear-gradient(135deg, ${theme.palette.grey[400]}, ${theme.palette.grey[400]} 5px, ${theme.palette.grey[300]} 5px, ${theme.palette.grey[300]} 10px);`
            : taskScenario
              ? `#${taskScenario.color}`
              : theme.palette.grey[400],
          zIndex: 0,
          opacity: 1,
          '&:hover': task.lunchBreak
            ? undefined
            : {
                background: darken(`#${taskScenario?.color}`, 0.1),
              },
          '&:active': task.lunchBreak
            ? undefined
            : {
                background: lighten(`#${taskScenario?.color}`, 0.1),
              },
          cursor: task.lunchBreak ? undefined : 'pointer',
        })}
      />
    );
  }
  return (
    <>
      {taskBox}
      {machineBox}
    </>
  );
}

export const GanttTaskBar = memo(GanttTaskBarComponent);
