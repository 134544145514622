import React, { useCallback, useContext, useMemo } from 'react';

export type WorkingDaysMap = Record<number, Record<number, Set<number>>>;
export type DaysInMonthMap = Record<number, Record<number, number>>;
export type IsWorkingDay = (date: Date) => boolean | undefined;

const WorkingDaysContext = React.createContext({
  workingDays: {} as WorkingDaysMap,
  daysInMonth: {} as DaysInMonthMap,
  isWorkingDay: (() => {
    return undefined;
  }) as IsWorkingDay,
});

import { getDaysInMonth } from 'date-fns';
import { useReadWorkingDayListQuery } from '../services/workingDayApi';

interface useWorkingDaysProviderProps {
  children: React.ReactNode;
}

/**
 * This contexts works a big cache for the working days and days-in-month count,
 * which turned out to be pretty expensive to compute in hooks (and consequently
 * on each page change).
 * The performance tab of the dev tools says this improves the related pages
 * rendering by roughly a 10x factor.
 */
function WorkingDaysProviderComponent(props: useWorkingDaysProviderProps) {
  const { children } = props;

  const workingDaysListApi = useReadWorkingDayListQuery();

  const daysInMonth = useMemo(() => {
    const newDaysInMonth: DaysInMonthMap = {};
    for (let i = 2022; i <= new Date().getFullYear() + 10; i++) {
      if (!newDaysInMonth[i]) {
        newDaysInMonth[i] = {};

        for (let m = 0; m < 12; m++) {
          newDaysInMonth[i][m] = getDaysInMonth(new Date(i, m, 1));
        }
      }
    }
    return newDaysInMonth;
  }, []);

  const workingDays = useMemo(() => {
    const workingDaysMap: WorkingDaysMap = {};

    if (workingDaysListApi.data) {
      for (const workingDay of workingDaysListApi.data) {
        if (workingDay.isWorkingDay) {
          const date = workingDay.date;
          const year = Number(date.substring(0, 4));
          const month = Number(date.substring(5, 7)) - 1;
          const day = Number(date.substring(8, 10));

          if (!workingDaysMap[year]) {
            workingDaysMap[year] = {};
          }

          if (!workingDaysMap[year][month]) {
            workingDaysMap[year][month] = new Set<number>();
          }

          workingDaysMap[year][month].add(day);
        }
      }
    }

    return workingDaysMap;
  }, [workingDaysListApi.data]);

  const isWorkingDay: IsWorkingDay = useCallback(
    (date: Date) => {
      return workingDays[date.getFullYear()]?.[date.getMonth()]?.has(date.getDate());
    },
    [workingDays],
  );

  return (
    <WorkingDaysContext.Provider
      value={{
        workingDays,
        isWorkingDay,
        daysInMonth,
      }}
    >
      {children}
    </WorkingDaysContext.Provider>
  );
}

export const WorkingDaysProvider = React.memo(WorkingDaysProviderComponent);

export function useWorkingDays() {
  return useContext(WorkingDaysContext);
}
