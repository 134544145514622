import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFilteredGanttTasks } from '../../../components/FilteredGanttTasks';
import { Subprocess } from '../../../entities/Subprocess';
import { GanttTask } from '../../../entities/Task';
import { useGanttTasksStats } from '../../../hooks/useGanttTasksStats';
import { useReadSubprocessListQuery } from '../../../services/subprocessApi';
import { TaskStatsCard, TaskStatsCardProps } from '../components/TasksStatsCard';
import { CardsGrid } from '../layout/CardsGrid';
import { OverviewInnerLayout } from '../layout/OverviewInnerLayout';
import { useOverviewQueryParams } from '../overviewQueryParams';

interface SubProcessTaskStatsCardProps extends Omit<TaskStatsCardProps, 'title' | 'stats'> {
  subprocess: Subprocess;
  ganttTasks: GanttTask[];
}

function SubProcessTaskStatsCard(props: SubProcessTaskStatsCardProps) {
  const { subprocess, ganttTasks, ...taskStatsCardProps } = props;
  const { showEmptyCards, excludeFutureTasks } = useOverviewQueryParams();

  const params = useParams<{ processId?: string; scenarioName?: string }>();

  const ganttTasksStats = useGanttTasksStats(
    ganttTasks,
    {
      subprocessId: subprocess.id,
      processId: params.processId ? Number(params.processId) : undefined,
      scenarioName: params.scenarioName,
    },
    { excludeFutureTasks, ignoreOtherScenario: true },
  );

  if (!showEmptyCards) {
    if (ganttTasksStats.finance.total === 0 && ganttTasksStats.supplier.total === 0) {
      return null;
    }
  }

  return <TaskStatsCard title={subprocess.name} stats={ganttTasksStats} {...taskStatsCardProps} />;
}

function SubProcessesGridComponent() {
  const { searchParams } = useOverviewQueryParams();

  const subProcessList = useReadSubprocessListQuery(undefined, { pollingInterval: 10000 });

  const sortedSubProcessList = useMemo(() => {
    return [...(subProcessList.data ?? [])].sort((a, b) => {
      if (a.name === 'OTHER') {
        return 1;
      }
      if (b.name === 'OTHER') {
        return -1;
      }
      return a.name.localeCompare(b.name);
    });
  }, [subProcessList]);

  const { filteredGanttTasks, error: ganttTasksError, isLoading: ganttTasksIsLoading } = useFilteredGanttTasks();

  return (
    <OverviewInnerLayout
      error={ganttTasksError ?? subProcessList.error}
      inProgress={subProcessList.isLoading || ganttTasksIsLoading}
    >
      <CardsGrid>
        {sortedSubProcessList.map((subprocess) => (
          <SubProcessTaskStatsCard
            key={subprocess.id}
            subprocess={subprocess}
            ganttTasks={filteredGanttTasks}
            detailPath={`./${subprocess.id}?${searchParams}`}
            taskDetailsPath={`${subprocess.id}/tasks`}
          />
        ))}
      </CardsGrid>
    </OverviewInnerLayout>
  );
}

export const SubProcessesGrid = React.memo(SubProcessesGridComponent);
